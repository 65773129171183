import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Folder from '../../models/folder.model';
import createAppAsyncThunk from '../../thunks/create-typed-async-thunk';
import { selectUserPreferences } from './user.slice';
import folderService from '../../../services/folder.service';
import { RootState } from '../../store';

/**
 * Represents the state of the folder slice in the application.
 * @typedef {Object} FolderSliceState
 * @property {Folder} folderSelected - The currently selected folder
 * @property {Folder[]} rootFolders - Array of top-level folders
 * @property {Object.<string, Folder>} subFoldersList - Subfolders list keyed by string identifier (id)
 */

export type FolderSliceState = {
    folderSelected: Folder;
    rootFolders: Folder[];
    subFoldersList: { [key: string]: Folder };
};

const initialState = {
    folderSelected: null,
    rootFolders: [],
    subFoldersList: {},
};

export const fetchFolders = createAppAsyncThunk('folders/fetchFolders', async (payload, thunkApi) => {
    const state = thunkApi.getState();
    const userPreferences = selectUserPreferences(state);
    let folderList = state.folder.rootFolders;
    const folderState: any = {};
    if (!state.user.canFetchData) {
        return thunkApi.rejectWithValue('Error while fetching folders');
    }
    if (state.folder.rootFolders.length === 0) {
        folderList = await folderService.listFolders();
        folderState.rootFolders = folderList;
    }
    let openFolder;
    if (userPreferences?.open_folder) {
        try {
            openFolder = await folderService.showFolder(userPreferences?.open_folder.id);
        } catch (e) {
            openFolder = null;
        }
    }

    const currentFolder = openFolder || folderList?.[0] || null;
    if (currentFolder) {
        folderState.folderSelected = currentFolder;
        const subFoldersList = await folderService.listSubFolders(currentFolder.id);
        folderState.subFoldersList = { [currentFolder.id]: subFoldersList };
    }
    return folderState;
});

const FolderSlice = createSlice({
    name: 'folder',
    initialState,
    reducers: {
        setRootFolders: (state, action: PayloadAction<Folder[]>) => {
            state.rootFolders = action.payload;
        },
        setSubFoldersList: (state, action: PayloadAction<{ folderId: string; data: Folder[] }>) => {
            state.subFoldersList = action.payload;
        },
        setSelectedFolder: (state, action: PayloadAction<Folder>) => {
            state.folderSelected = action.payload;
        },
        updateSubFoldersList: (state, action: PayloadAction<{ folderId: string; data: Folder[] }>) => {
            const { folderId, data } = action.payload;
            state.subFoldersList[folderId] = data;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFolders.fulfilled, (state, { payload }) => {
            Object.assign(state, payload);
        });
        builder.addCase(fetchFolders.rejected, (state, { payload }) => {
            console.error(`Error fetching folders: ${payload}`);
        });
    },
});

export const { updateSubFoldersList, setSelectedFolder, setRootFolders, setSubFoldersList } = FolderSlice.actions;

// Selectors
export const selectSelectedFolder = (state: RootState) => state.folder.folderSelected;

export const selectRootFolders = (state: RootState) => state.folder.rootFolders;

export const selectAllSubFolders = (state: RootState) => state.folder.subFoldersList;

export default FolderSlice.reducer;
