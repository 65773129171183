import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export type NotificationError = { title: string; description: string };
export type RequestError = { title: string; error: any };
type ErrorStateSlice = {
    notificationError?: NotificationError;
    requestError?: RequestError;
    config?: any
}
const initialState: ErrorStateSlice = {
    notificationError: null,
    requestError: null,
};

const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        setNotificationError: (state, action: PayloadAction<NotificationError>) => {
            state.notificationError = action.payload;
        },
        clearNotificationError: (state) => {
            state.notificationError = null;
        },
        setRequestError: (state, action: PayloadAction<RequestError>) => {
            state.requestError = action.payload;
        },
        clearRequestError: (state) => {
            state.requestError = null;
        },
    },
});

export const { setNotificationError, clearNotificationError, setRequestError, clearRequestError } = errorSlice.actions;

// Selectors

export const selectNotificationError = (state: RootState): NotificationError | null => state.error.notificationError;
export const selectRequestError = (state: RootState): RequestError | null => state.error.requestError;

export default errorSlice.reducer;
