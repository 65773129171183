import { createListenerMiddleware, TypedStartListening } from '@reduxjs/toolkit';
import { RootState, AppDispatch } from '../store';
import { selectPlanningSelected } from '../slices/app/planning.slice';
import { getPlanningData } from '../thunks/planning-slice.thunk';
import {
    getPlanningTabs,
} from '../slices/app/tab.slice';

const onPlanningChangeMiddleware = createListenerMiddleware();

type AppStartListening = TypedStartListening<RootState, AppDispatch>;

const startListening = onPlanningChangeMiddleware.startListening as AppStartListening;

/**
 * Configures a listener to respond to changes in the selected planning.
 * This listener will fetch tabs, choose an active tab, and update planning data when a new planning is selected.
 * 
 * @param {Object} config - The configuration object for the listener.
 * @param {Function} config.predicate - A function that determines if the effect should run based on state changes.
 * @param {Function} config.effect - An async function that performs actions when the predicate is true.
 * 
 * @returns {void}
 */
startListening({
    /**
     * Predicate function to determine if the effect should run.
     * 
     * @param {Action} action - The Redux action that triggered this listener.
     * @param {RootState} currentState - The current state of the Redux store.
     * @param {RootState} previousState - The previous state of the Redux store.
     * @returns {boolean} True if the selected planning has changed, false otherwise.
     */
    predicate: (action, currentState, previousState) => {
        const currentPlanning = selectPlanningSelected(currentState);
        const previousPlanning = selectPlanningSelected(previousState);
        return currentPlanning?.id !== previousPlanning?.id;
    },
    /**
     * Effect function that runs when the predicate returns true.
     * Fetches planning tabs, chooses an active tab, and updates planning data.
     * 
     * @param {Action} action - The Redux action that triggered this listener.
     * @param {Object} listenerApi - The API for dispatching actions and accessing the store.
     * @param {Function} listenerApi.dispatch - Function to dispatch Redux actions.
     * @param {Function} listenerApi.getState - Function to get the current Redux state.
     * @returns {Promise<void>}
     */
    effect: async (action, { dispatch, getState }) => {
        const state = getState();
        const planningSelected = selectPlanningSelected(state);

        if (planningSelected && planningSelected.id) {
            console.log('Planning selected changed:', planningSelected);
            await dispatch(getPlanningTabs());
            await dispatch(getPlanningData());
        }
    },
});

export default onPlanningChangeMiddleware;
