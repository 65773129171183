/* eslint react/jsx-props-no-spreading: "off" */
import React from 'react';
import PropTypes from 'prop-types';

import { Route } from 'react-router-dom';
import { Row } from 'antd';
import LangageChooser from '../components/langage-selector/LangageChooser';
import logoFr from '../assets/images/logo.png';
import logoEn from '../assets/images/logo_en.png';
import { randomIntFromInterval } from '../helpers/object-helper';

const LoginLayout = ({ children }) => {
    let logo = logoFr;
    switch (window.localStorage.getItem('lng')) {
        case 'fr':
            logo = logoFr;
            break;
        case 'en':
            logo = logoEn;
            break;

        default:
            break;
    }
    return (
        <div
            className="min-h-screen animate-background bg-cover flex items-center"
            style={{ backgroundImage: `url('/background/${randomIntFromInterval(1, 25)}.jpeg')` }}
        >
            <div
                className="mx-auto p-2"
                style={{ width: '100%', maxWidth: 454, backgroundColor: 'rgba(255,255,255,0.85)' }}
            >
                <div>
                    <img className="mx-auto" style={{ paddingBottom: 7 }} src={logo} alt="Gantt Manager" />
                </div>
                <div className="p-6 border border-t border-color-black shadow-lg">{children}</div>
            </div>
        </div>
    );
};

LoginLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

const LoginLayoutRoute = ({ Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) => (
            <LoginLayout>
                <Row className="flex justify-end">
                    <LangageChooser />
                </Row>
                <Component {...props} />
            </LoginLayout>
        )}
    />
);

LoginLayoutRoute.propTypes = {
    Component: PropTypes.any.isRequired,
};

export default LoginLayoutRoute;
