import { configureStore } from '@reduxjs/toolkit';
import userSliceReducer from './slices/app/user.slice';
import tabSliceReducer from './slices/app/tab.slice';
import planningSliceReducer from './slices/app/planning.slice';
import folderSliceReducer from './slices/app/folder.slice';
import errorSliceReducer from './slices/app/error.slice';
import activitySliceReducer from './slices/app/activity.slice';
import calendarSliceReducer from './slices/app/calendar.slice';
import needsSliceReducer from './slices/needs/needs.slice';
import activityCalendarSliceReducer from './slices/slicing/activity-calendar.slice';
import needChartSliceReducer from './slices/chart/need-chart.slice';
import errorLoggingMiddleware from './middlewares/error.middleware';
import onModeChangeMiddleware from './middlewares/mode-change.middleware';
import onPlanningChangeMiddleware from './middlewares/planning-change.middleware';

export const store = configureStore({
    reducer: {
        error: errorSliceReducer,
        user: userSliceReducer,
        tab: tabSliceReducer,
        planning: planningSliceReducer,
        folder: folderSliceReducer,
        activity: activitySliceReducer,
        calendar: calendarSliceReducer,
        needs: needsSliceReducer,
        needChart: needChartSliceReducer,
        activityCalendarSlice: activityCalendarSliceReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ immutableCheck: false, serializableCheck: false })
            .concat(errorLoggingMiddleware)
            .prepend(onModeChangeMiddleware.middleware)
            .prepend(onPlanningChangeMiddleware.middleware),
    devTools: {
        serialize: true,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
