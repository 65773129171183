import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';

import { Row, Col, Typography, Spin } from 'antd';
import background from '../assets/images/guest-background.jpg';
import WalletService from '../services/wallet.service';
import AuthService from '../services/auth.service';
import UserService from '../services/user.service';
import FolderService from '../services/folder.service';

const { Title } = Typography;

const AccessPlanning = ({
    match: {
        params: { idPrg, mode, idPlanning },
    },
}) => {
    const history = useHistory();
    const [error, setError] = useState(null);

    const setProgramUrl = ({ name, id, url }) => {
        localStorage.setItem('prg', JSON.stringify({ name, id, url }));
        history.push(`/planning`);
        window.location.reload();
    };
    const setTargetUrl = (url) => {
        localStorage.setItem('targetUrl', url);
    };
    const unsetTargetUrl = () => {
        localStorage.removeItem('targetUrl');
    };
    setTargetUrl(window.location.href);

    useEffect(() => {
        (async () => {
            try {
                const program = await WalletService.getProgram(idPrg);
                let userPreferences;
                await AuthService.getPreferences(program.url)
                    .then((pref) => {
                        userPreferences = pref;
                    })
                    .catch(() => {
                        userPreferences = {};
                    });
                if (program) {
                    await UserService.getSessionByURL(program.url);
                    const planning = await FolderService.showPlanningByUrl(program.url, idPlanning);
                    const planningFolder = await FolderService.showFolderByUrl(program.url, planning.folderId);
                    const modeSelected = mode;
                    const newPreferences = Object.assign(userPreferences, {
                        open_folder: planningFolder,
                        open_planning: { ...userPreferences.open_planning, [modeSelected]: planning },
                        open_mode: mode,
                    });
                    await AuthService.updateUserPreferences(newPreferences, program.url);
                    await UserService.updateCurrentPlanning(planning.id, planning.name, program.url);
                    unsetTargetUrl();
                    setProgramUrl(program);
                }
            } catch (e) {
                unsetTargetUrl();
                setError("Une erreur est survenue ou vous n'avez pas les droits nécessaires.");
            }
        })();
    }, []);

    return (
        <div className="h-screen bg-cover overflow-hidden" style={{ backgroundImage: `url('${background}')` }}>
            <header>
                <Row className="bg-primary justify-between h-12 px-12">
                    <Col span={14} className="flex items-center">
                        <Title level={3} style={{ marginBottom: '0px', color: 'white', fontWeight: 'normal' }}>
                            Gantt Manager
                        </Title>
                    </Col>
                </Row>
            </header>
            <div className="h-screen flex items-center justify-center" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                {!error ? (
                    <Spin spinning tip="Vous allez être redirigé vers le planning spécifié." />
                ) : (
                    <label className="text-white text-center">
                        <p>{error}</p> <Link to="/">Cliquez ici pour revenir à la page d&apos;accueil.</Link>
                    </label>
                )}
            </div>
        </div>
    );
};

export default AccessPlanning;
