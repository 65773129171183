import React from 'react';
import WalletHeader from '../components/wallet/Header';
import WalletFooter from '../components/wallet/Footer';
import ProgramList from '../components/wallet/ProgramList';
import { randomIntFromInterval } from '../helpers/object-helper';
import SessionInfo from '../components/extra/SessionInfo';

function Wallet() {
    return (
        <div
            className="h-screen bg-cover"
            style={{ backgroundImage: `url('/background/${randomIntFromInterval(1, 25)}.jpeg')` }}
        >
            <WalletHeader />
            <ProgramList />
            <WalletFooter />
            <div
                style={{
                    position: 'absolute',
                    left: '5px',
                    bottom: '0px',
                    zIndex: 10,
                }}
            >
                <SessionInfo />
            </div>
        </div>
    );
}

export default Wallet;
