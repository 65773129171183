import { Middleware, isRejected } from '@reduxjs/toolkit';

/**
 * A Redux middleware that logs rejected actions to the console.
 *
 * @remarks
 * This middleware checks if an action is a rejected action (i.e., it has an `error` property and is rejected by the `isRejected` function).
 * If the action is rejected, it logs the action type and error message to the console.
 *
 * @returns {Middleware} - The error logging middleware function.
 *
 */

const errorLoggingMiddleware: Middleware = () => (next) => (action: any) => {
  // Check if the action is a rejected action
  if (action.error && isRejected(action)) {
    console.error('REDUX Rejected action:', action.type);
    console.error('Error:', action.error.message);
    
  }

  return next(action);
};

export default errorLoggingMiddleware;