/**
 * Slice containing user informations, global preferences
 */
/* eslint-disable no-param-reassign */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isNil } from 'lodash';
import i18n from '../../../i18n';
import User from '../../models/user.model';
import UserGlobalPreferences from '../../models/global-preference.model';
import authService from '../../../services/auth.service';
import computeDateFormat from '../../../components/utils/global-preferences/date-format-utils';
import { RootState } from '../../store';
import createAppAsyncThunk from '../../thunks/create-typed-async-thunk';
import { setRequestError } from './error.slice';
import { buildRequestErrorObject } from '../../utils/ErrorSliceUtils';
import { Team } from '../../models/planning.model';

export type UserSliceState = {
    currentUser: User;
    userPreferences: UserGlobalPreferences;
    updatingData: Boolean;
    canFetchData: Boolean;
    teamList: Team[];
};

const initialState: UserSliceState = {
    currentUser: {},
    userPreferences: null,
    updatingData: false,
    canFetchData: false,
    teamList: [],
};

export const getUserInformation = createAppAsyncThunk('userInfos/getInformation', async () => {
    const programInfo = JSON.parse(localStorage.getItem('prg'));
    const canFetchData = !isNil(programInfo);
    await authService.getSession();
    const user = authService.getCurrentUser();
    return {
        userInfo: user,
        canFetchData,
    };
});

export const getUserPreferences = createAppAsyncThunk(
    'userInfos/getGlobalPreferences',
    async (payload, { getState, rejectWithValue, dispatch }) => {
        const state = getState();
        try {
            if (state.user.canFetchData) {
                const globalPreferences = await authService.getPreferences();
                computeDateFormat(globalPreferences);
                return globalPreferences;
            }
        } catch (error: any) {
            if (error?.response?.data?.type !== 'no_preference_found') {
                dispatch(setRequestError(buildRequestErrorObject(i18n.t('user_context.error_get_preference'), error)));
            }
        }
        return rejectWithValue('Error while fetching user preferences');
    }
);

export const updateUserPreferences = createAppAsyncThunk(
    'user/updateUserPreferences',
    async (newPreferences: any, { getState, rejectWithValue, dispatch }) => {
        /* eslint-disable-next-line */
        const userPreferences = { ...selectUserPreferences(getState()) };
        console.log('🚀 ~ updatedPreference:', newPreferences);
        const updatedPreference = Object.assign(userPreferences, newPreferences);

        try {
            const userPreferencesUpdated = await authService.updateUserPreferences(updatedPreference);
            computeDateFormat(userPreferencesUpdated);
            return userPreferencesUpdated;
        } catch (error) {
            dispatch(setRequestError({ error, title: i18n.t('error_update_preference') }));
            return rejectWithValue('Error while updating user preferences');
        }
    }
);

const userSlice = createSlice({
    name: 'userInfos',
    initialState,
    reducers: {
        setCurrentUser: (state, action: PayloadAction<Partial<User>>) => {
            state.currentUser = { ...state.currentUser, ...action.payload };
        },
        // Update team list
        setTeamList: (state, action: PayloadAction<Team[]>) => {
            state.teamList = action.payload;
        },

        // Add team to team list
        addTeam: (state, action: PayloadAction<Team>) => {
            state.teamList.push(action.payload);
        },

        // Remove team from team list
        removeTeam: (state, action: PayloadAction<number>) => {
            state.teamList = state.teamList.filter((team) => team.id !== action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUserInformation.fulfilled, (state, { payload: { userInfo, canFetchData } }) => {
            state.currentUser = userInfo;
            state.canFetchData = canFetchData;
        });
        builder.addCase(getUserPreferences.fulfilled, (state, { payload }) => {
            state.userPreferences = payload;
        });
        builder.addCase(getUserPreferences.rejected, (state) => {
            state.userPreferences = {};
        });
        builder.addCase(updateUserPreferences.fulfilled, (state, { payload }) => {
            state.userPreferences = payload;
        });
    },
});

export const { setTeamList, addTeam, removeTeam } = userSlice.actions;

const selectUserState = (state: RootState) => state.user;
export const selectTeamList = (state: RootState) => state.user.teamList;
export const selectCurrentUser = (state: RootState) => state.user.currentUser;
export const selectCanFetchData = (state: RootState) => state.user.canFetchData;
export const selectUserPreferences = createSelector([selectUserState], (userState) => userState.userPreferences);

export default userSlice.reducer;
