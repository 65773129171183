import { isNil } from 'lodash';
import { TypedStartListening, UnknownAction, createListenerMiddleware } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../store';
import { setModeSelected } from '../slices/app/planning.slice';
import { selectUserPreferences } from '../slices/app/user.slice';
import { fetchFolders } from '../slices/app/folder.slice';
import getPlanning from '../thunks/planning-slice.thunk';
import { getTimeUnits } from '../thunks/calendar-slice.thunk';

const onModeChangeMiddleware = createListenerMiddleware();

type AppStartListening = TypedStartListening<RootState, AppDispatch>;

const startListening =   onModeChangeMiddleware.startListening as AppStartListening;

/**
 * Sets up a listener for changes in user preferences and updates the application state accordingly.
 * This function is triggered when user preferences are available in the state.
 * It updates the selected mode, fetches folders, time units, and planning data if necessary.
 *
 * @param {Object} options - The configuration object for the listener.
 * @param {function} options.predicate - A function that determines whether the effect should run.
 * @param {function} options.effect - An async function that performs the side effects.
 * @param {UnknownAction} options.effect.action - The action that triggered the effect.
 * @param {Object} options.effect.listenerApi - An object containing utility functions.
 * @param {function} options.effect.listenerApi.getState - A function to get the current state.
 * @param {function} options.effect.listenerApi.dispatch - A function to dispatch actions.
 * @param {function} options.effect.listenerApi.cancelActiveListeners - A function to cancel active listeners.
 * @returns {void}
 */
startListening({
  predicate: (action: UnknownAction, currentState: RootState) => 
    !isNil(selectUserPreferences(currentState)),
  effect: async (action, {getState, dispatch, cancelActiveListeners}) => {
    try {
      // Cancel any active listeners to prevent multiple simultaneous executions
      cancelActiveListeners();

      // Get the current state
      const state = getState();
      const userPreferences = selectUserPreferences(state);
      const openMode = userPreferences.open_mode || 'live';

      // Dispatch setModeSelected if the modeSelected is different from openMode
      if (state.planning.modeSelected !== openMode && state.user.canFetchData) {
        await dispatch(setModeSelected(openMode));
        await dispatch(fetchFolders());
        await dispatch(getTimeUnits());
        await dispatch(getPlanning());
      }
    } catch (error) {
      console.error('Error in onModeChangeMiddleware effect:', error);
    }
  }
});

export default onModeChangeMiddleware;