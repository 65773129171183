import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import SubmitButton from '../../components/common/forms/SubmitButton';

const PasswordReset = () => {
    const passwordRef = useRef(null);

    useEffect(() => {
        passwordRef.current.focus({
            cursor: 'start',
        });
    }, []);

    const [form] = Form.useForm();
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const submitForm = (values) => {
        setIsLoading(true);
        console.log(values);
    };

    const validationRules = {
        password: [
            {
                required: true,
                message: 'Le mot de passe est obligatoire',
            },
        ],
        passwordConfirmation: [
            {
                required: true,
                message: 'La confirmation du mot de passe est obligatoire',
            },
        ],
    };

    return (
        <>
            <h1>Réinitialisation du mot de passe</h1>
            <p className="form-description">Entrez votre nouveau mot de passe</p>
            <Form
                className="mt-9 mx-auto login-form"
                name="login_form"
                layout="vertical"
                form={form}
                initialValues={{ password: '', password_confirmation: '' }}
                requiredMark={false}
                validateTrigger="onBlur"
                onFinish={submitForm}
            >
                <div className="relative">
                    <Form.Item label="Mot de passe" name="password" rules={validationRules.password}>
                        <Input
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Mot de passe"
                            className="password-input"
                            ref={passwordRef}
                        />
                    </Form.Item>
                    <FontAwesomeIcon
                        icon={showPassword ? faEyeSlash : faEye}
                        className="absolute top-9 right-4 cursor-pointer text-gray-400"
                        onClick={() => setShowPassword(!showPassword)}
                    />
                </div>
                <div className="relative">
                    <Form.Item
                        label="Confirmation du mot de passe"
                        name="password_confirmation"
                        rules={validationRules.passwordConfirmation}
                    >
                        <Input
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Confirmation du mot de passe"
                            className="password-input"
                        />
                    </Form.Item>
                    <FontAwesomeIcon
                        icon={showPasswordConfirmation ? faEyeSlash : faEye}
                        className="absolute top-9 right-4 cursor-pointer text-gray-400"
                        onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                    />
                </div>
                <Form.Item className="pt-4">
                    <SubmitButton loading={isLoading}>Envoyer</SubmitButton>
                    <p className="text-center text-sm text-gray-400 italic mt-2">
                        <Link to="/">Connexion</Link>
                    </p>
                </Form.Item>
            </Form>
        </>
    );
};

export default PasswordReset;
