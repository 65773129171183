import { NotificationError, RequestError } from '../slices/app/error.slice';

export const buildNotificationErrorObject = (title: string, description: string, config: any = {}) => {
    const errorObject = { title, description, config };
    return errorObject as NotificationError;
};

export const buildRequestErrorObject = (title: string, error: any) => {
    const errorObject = { title, error };
    return errorObject as RequestError;
};

export default buildNotificationErrorObject;
