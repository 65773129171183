import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import Activity from '../../models/activity.model';
import { RootState } from '../../store';
import { ACTIVITY_STATE } from '../../../helpers/planning';
import { canModify, selectIsMultiJobUser, selectIsUserJob } from './planning.slice';
import { ALL_JOBS } from '../../../constants/Jobs';

const activitiesAdapter = createEntityAdapter({
    selectId: (activity: Activity) => activity.id,
});

const baselineAdapter = createEntityAdapter({
    selectId: (activity: Activity) => activity.identity,
});

export type ActivitySliceState = {
    activitiesDictionary: EntityState<Activity, number>;
    baseLineDictionary: EntityState<Activity, string>;
    filteredActivitiesCount: number;
};

const initialState: ActivitySliceState = {
    activitiesDictionary: activitiesAdapter.getInitialState(),
    baseLineDictionary: baselineAdapter.getInitialState(),
    filteredActivitiesCount: 0
};

const activitySlice = createSlice({
    name: 'activity',
    initialState,
    reducers: {
        setActivities: (state, action: PayloadAction<Activity[]>) => {
            activitiesAdapter.setAll(state.activitiesDictionary, action.payload);
        },
        updateActivity: (state, action: PayloadAction<Activity>) => {
            activitiesAdapter.upsertOne(state.activitiesDictionary, action.payload);
        },
        setBaselineActivities: (state, action: PayloadAction<Activity[]>) => {
            baselineAdapter.setAll(state.baseLineDictionary, action.payload);
        },
        updateBaselineActivity: (state, action: PayloadAction<Activity>) => {
            baselineAdapter.upsertOne(state.baseLineDictionary, action.payload);
        },
        removeAllActivities: (state) => {
            activitiesAdapter.removeAll(state.activitiesDictionary);
        },
        removeAllBaselineActivities: (state) => {
            baselineAdapter.removeAll(state.baseLineDictionary);
        },
        removeActivity: (state, action: PayloadAction<number>) => {
            activitiesAdapter.removeOne(state.activitiesDictionary, action.payload);
        },
        setFilteredActivitiesCount: (state, action: PayloadAction<number>) => {
            state.filteredActivitiesCount = action.payload;
        },
        // Add more reducers as needed
    },
});

export const {
    setActivities,
    updateActivity,
    setBaselineActivities,
    updateBaselineActivity,
    removeAllActivities,
    removeAllBaselineActivities,
    removeActivity,
    setFilteredActivitiesCount,
} = activitySlice.actions;

// Selectors
export const {
    selectAll: selectAllActivities,
    selectById: selectActivityById,
    selectIds: selectActivityIds,
    selectEntities: selectActivityEntities, // Returns all entities as a dictionary
} = activitiesAdapter.getSelectors((state: RootState) => state.activity.activitiesDictionary);


export const {
    selectAll: selectAllBaselineActivities,
    selectById: selectBaselineActivityById,
    selectIds: selectBaselineActivityIds,
    selectEntities: selectBaselineActivityEntities, // Returns all entities as a dictionary
} = baselineAdapter.getSelectors((state: RootState) => state.activity.baseLineDictionary);

export const selectFilteredActivitiesCount = (state: RootState) => state.activity.filteredActivitiesCount;

export const canEditAnActivity = (state: RootState) => (id, status, jobId) => {
    const isProject = +id === state.planning.planningSelected.rootActivityId;
    const archived = state.planning.modeSelected === 'archive';
    const validated = status !== ACTIVITY_STATE.UNVALID;
    const noWriteAccess = !canModify(state);
    const isMultiJobActivity = (jobId || []).filter((job) => job === ALL_JOBS).length > 0;
    const noJobRule = !isProject && !selectIsUserJob(state)(jobId) && !selectIsMultiJobUser(state) && !isMultiJobActivity;
    const noEdit = archived || validated || noWriteAccess || noJobRule;
    return !noEdit;
}

export default activitySlice.reducer;
