import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import Calendar, { DayDefinition } from '../../models/calendar.model';
import { RootState } from '../../store';

/**
 * Represents the state of the calendar slice in the application.
 * @typedef {Object} CalendarSliceState
 * @property {EntityState<Calendar, number>} calendarsDictionary - A dictionary of calendars indexed by their id
 * @property {DayDefinition[]} timeUnits - An array of time units
 * */

export type CalendarSliceState = {
    calendarsDictionary: EntityState<Calendar, number>;
    timeUnits: DayDefinition[];

}
const calendarAdapter = createEntityAdapter({
  selectId: (calendar: Calendar) => calendar.id,
});

const initialState: CalendarSliceState = {
    calendarsDictionary: calendarAdapter.getInitialState(),
    timeUnits: [],
}

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setCalendars: (state, action: PayloadAction<Calendar[]>) => {
      calendarAdapter.setAll(state.calendarsDictionary, action.payload);
    },
    addCalendar: (state, action: PayloadAction<Calendar>) => {
      calendarAdapter.addOne(state.calendarsDictionary, action.payload);
    },
    updateCalendar: (state, action: PayloadAction<Calendar>) => {
      calendarAdapter.upsertOne(state.calendarsDictionary, action.payload);
    },
    removeCalendar: (state, action: PayloadAction<number>) => {
      calendarAdapter.removeOne(state.calendarsDictionary, action.payload);
    },
    setTimeUnits: (state, action: PayloadAction<DayDefinition[]>) => {
        state.timeUnits = action.payload;
    },
  }
});

export const { setCalendars, addCalendar, updateCalendar, removeCalendar, setTimeUnits } = calendarSlice.actions;

// Selectors
export const {
  selectAll: selectAllCalendars,
  selectById: selectCalendarById,
  selectIds: selectCalendarIds,
  selectEntities: selectCalendarEntities, // Returns all entities as a dictionary
} = calendarAdapter.getSelectors((state: RootState) => state.calendar.calendarsDictionary);

export const selectTimeUnits = (state: RootState) => state.calendar.timeUnits;
export const selectTimeUnitById = (state: RootState, id: number) => state.calendar.timeUnits.find((tu) => tu.id === id); // TODO replace in all files with timeUnits.find

export default calendarSlice.reducer;