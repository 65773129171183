import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import NeedService from '../../../services/need.service';
import activityService from '../../../services/activity.service';
import { findParentObject } from '../../../helpers/tree-helper';
import Need from '../../models/need.model';
import formatNeeds from '../../utils/NeedsSliceUtils';



interface NeedsState {
  selectedGlobalNeed: Need | null;
  selectedPlanningNeed: Need | null;
  globalNeedList: Need[] | null;
  planningNeedList: Need[] | null;
  loading: boolean;
  error: string | null;
}

const initialState: NeedsState = {
  selectedGlobalNeed: null,
  selectedPlanningNeed: null,
  globalNeedList: null,
  planningNeedList: null,
  loading: false,
  error: null,
};


export const fetchGlobalNeeds = createAsyncThunk(
  'needs/fetchGlobalNeeds',
  async (_, { rejectWithValue }) => {
    try {
      const list = await NeedService.getNeedsTreeByParent(-1);
      return formatNeeds([list], null, false);
    } catch (error) {
      return rejectWithValue('Failed to fetch global needs');
    }
  }
);

export const fetchPlanningNeeds = createAsyncThunk(
  'needs/fetchPlanningNeeds',
  async (planningRootActivityId: number, { rejectWithValue }) => {
    try {
      const rootActivityConfig = await activityService.getRootActivityConfig(planningRootActivityId);
      if (rootActivityConfig.defaultLevelId) {
        const list = await NeedService.getNeedsTreeByParent(rootActivityConfig.defaultLevelId);
        return formatNeeds([list], null, true);
      }
      return null;
    } catch (error) {
      return rejectWithValue('Failed to fetch planning needs');
    }
  }
);

const needsSlice = createSlice({
  name: 'needs',
  initialState,
  reducers: {
    setSelectedGlobalNeed: (state, action: PayloadAction<Need | null>) => {
      state.selectedGlobalNeed = action.payload;
    },
    setSelectedPlanningNeed: (state, action: PayloadAction<Need | null>) => {
      state.selectedPlanningNeed = action.payload;
    },
    addNeed: (state, action: PayloadAction<{ need: Need, isPlanning: boolean }>) => {
      const { need, isPlanning } = action.payload;
      const needList = isPlanning ? state.planningNeedList : state.globalNeedList;
      if (needList) {
        const parentObject = findParentObject(need.parent, needList);
        if (parentObject) {
          parentObject.children = parentObject.children || [];
          parentObject.children.push(need);
        }
      }
    },
    updateNeed: (state, action: PayloadAction<{ need: Need, isPlanning: boolean }>) => {
      const { need, isPlanning } = action.payload;
      const needList = isPlanning ? state.planningNeedList : state.globalNeedList;
      const selectedNeed = isPlanning ? state.selectedPlanningNeed : state.selectedGlobalNeed;
      if (needList) {
        const oldParentObject = findParentObject(selectedNeed?.parent || null, needList);
        const newParentObject = findParentObject(need.parent, needList);
        
        if (oldParentObject && newParentObject) {
          if (oldParentObject.id !== newParentObject.id) {
            oldParentObject.children = oldParentObject.children?.filter(child => child.id !== need.id);
            newParentObject.children = newParentObject.children || [];
            newParentObject.children.push(need);
          } else {
            const index = oldParentObject.children?.findIndex(child => child.id === need.id) || -1;
            if (index !== -1) {
              oldParentObject.children![index] = need;
            }
          }
        }
      }
    },
    deleteNeed: (state, action: PayloadAction<{ need: Need, isPlanning: boolean }>) => {
      const { need, isPlanning } = action.payload;
      const needList = isPlanning ? state.planningNeedList : state.globalNeedList;
      if (needList) {
        const parentObject = findParentObject(need.parent, needList);
        if (parentObject && parentObject.children) {
          parentObject.children = parentObject.children.filter(child => child.id !== need.id);
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGlobalNeeds.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGlobalNeeds.fulfilled, (state, action) => {
        state.loading = false;
        state.globalNeedList = action.payload;
      })
      .addCase(fetchGlobalNeeds.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchPlanningNeeds.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPlanningNeeds.fulfilled, (state, action) => {
        state.loading = false;
        state.planningNeedList = action.payload;
      })
      .addCase(fetchPlanningNeeds.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { 
  setSelectedGlobalNeed, 
  setSelectedPlanningNeed, 
  addNeed, 
  updateNeed, 
  deleteNeed 
} = needsSlice.actions;

export const selectSelectedGlobalNeed = (state: RootState) => state.needs.selectedGlobalNeed;
export const selectSelectedPlanningNeed = (state: RootState) => state.needs.selectedPlanningNeed;
export const selectGlobalNeedList = (state: RootState) => state.needs.globalNeedList;
export const selectPlanningNeedList = (state: RootState) => state.needs.planningNeedList;
export const selectNeedsLoading = (state: RootState) => state.needs.loading;
export const selectNeedsError = (state: RootState) => state.needs.error;

export default needsSlice.reducer;