import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NEED_CHART_DATA, NEED_CHART_TYPE, NEED_HISTOGRAM_TYPE, NEED_TYPE, SLICE_DATA_TYPE } from '../../../constants/Needs';
import NeedChartData from '../../models/need-chart.model';
import Need from '../../models/need.model';
import { TimeSlice } from '../../models/time-slice.model';
import { computeActivityCalendarTemporalSliceDictionary } from '../../thunks/need-chart-slice.thunk';
import { RootState } from '../../store';

export const DEFAULT_CHART_OPTIONS = {
    chartType: NEED_CHART_TYPE.BAR,
    histogramType: NEED_HISTOGRAM_TYPE.STACKED,
    needType: NEED_TYPE.NON_CONSUMMABLE,
    dataType: NEED_CHART_DATA.INSTANTANEOUS,
    sliceDataType: SLICE_DATA_TYPE.QUANTITY,
    isGanttActivityFiltered: true,
    effortTimeUnitId: -9,
    shownNeedsPanel: ['global', 'planning']
};

interface ChartState {
    chartData: NeedChartData[];
    needList: Need[];
    shouldUpdateChartData: boolean;
    chartOptions: typeof DEFAULT_CHART_OPTIONS;
    activityCalendarTemporalNeedSliceDictionary: {
        [key: number]: TimeSlice[]
    };
}

const initialState: ChartState = {
    shouldUpdateChartData: false,
    chartData: [],
    needList: [],
    chartOptions: DEFAULT_CHART_OPTIONS,
    activityCalendarTemporalNeedSliceDictionary: null,
};

const chartSlice = createSlice({
    name: 'chart',
    initialState,
    reducers: {
        setChartData: (state, action: PayloadAction<any[]>) => {
            state.chartData = action.payload;
        },
        setNeedList: (state, action: PayloadAction<any[]>) => {
            state.needList = action.payload;
        },
        setChartOptions: (state, action: PayloadAction<Partial<typeof DEFAULT_CHART_OPTIONS>>) => {
            state.chartOptions = { ...state.chartOptions, ...action.payload };
        },
        setActivityCalendarTemporalNeedSliceDictionary: (state, action: PayloadAction<any>) => {
            state.activityCalendarTemporalNeedSliceDictionary = action.payload;
        },
        setShouldUpdateChartData: (state, action: PayloadAction<boolean>) => {
          state.shouldUpdateChartData = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
          .addCase(computeActivityCalendarTemporalSliceDictionary.fulfilled, (state, action) => {
            state.activityCalendarTemporalNeedSliceDictionary = action.payload;
          })
          // .addCase(computeChartData.fulfilled, (state, action) => {
          //   state.chartData = action.payload;
          // });
      },
});

export const {
    setChartData,
    setNeedList,
    setChartOptions,
    setActivityCalendarTemporalNeedSliceDictionary,
    setShouldUpdateChartData,
} = chartSlice.actions;

// Selectors

// Basic selectors
export const selectChartData = (state: RootState) => state.needChart.chartData;
export const selectNeedList = (state: RootState) => state.needChart.needList;
export const selectChartOptions = (state: RootState) => state.needChart.chartOptions;
export const selectActivityCalendarTemporalNeedSliceDictionary = (state: RootState) => state.needChart.activityCalendarTemporalNeedSliceDictionary;
export const selectShouldUpdateChartData = (state: RootState) => state.needChart.shouldUpdateChartData; 
// Derived selectors
export const selectChartType = createSelector(
  selectChartOptions,
  (chartOptions) => chartOptions.chartType
);

export const selectHistogramType = createSelector(
  selectChartOptions,
  (chartOptions) => chartOptions.histogramType
);

export const selectNeedType = createSelector(
  selectChartOptions,
  (chartOptions) => chartOptions.needType
);

export const selectDataType = createSelector(
  selectChartOptions,
  (chartOptions) => chartOptions.dataType
);

export const selectSliceDataType = createSelector(
  selectChartOptions,
  (chartOptions) => chartOptions.sliceDataType
);

export const selectIsGanttActivityFiltered = createSelector(
  selectChartOptions,
  (chartOptions) => chartOptions.isGanttActivityFiltered
);

export const selectEffortTimeUnitId = createSelector(
  selectChartOptions,
  (chartOptions) => chartOptions.effortTimeUnitId
);

export const selectShownNeedsPanel = createSelector(
  selectChartOptions,
  (chartOptions) => chartOptions.shownNeedsPanel
);

// More complex selectors
export const selectSelectedNeeds = createSelector(
  selectNeedList,
  (needList) => needList.filter(need => need.selected)
);

export const selectSelectedNeedIds = createSelector(
  selectSelectedNeeds,
  (selectedNeeds) => selectedNeeds.map(need => need.id)
);

export const selectChartDataForSelectedNeeds = createSelector(
  selectChartData,
  selectSelectedNeeds,
  (chartData, selectedNeeds) => {
    const selectedNeedNames = selectedNeeds.map(need => need.name);
    return chartData.map(dataPoint => {
      const filteredDataPoint = { date: dataPoint.date };
      selectedNeedNames.forEach(needName => {
        if (dataPoint[needName] !== undefined) {
          filteredDataPoint[needName] = dataPoint[needName];
        }
      });
      return filteredDataPoint;
    });
  }
);

export const selectHasTemporalNeedSlices = createSelector(
  selectActivityCalendarTemporalNeedSliceDictionary,
  (dictionary) => dictionary !== null && Object.keys(dictionary).length > 0
);

export default chartSlice.reducer;