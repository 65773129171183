import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Form, Input } from 'antd';
import SubmitButton from '../../components/common/forms/SubmitButton';
import AuthService from '../../services/auth.service';

const ForgottenPassword = () => {
    const emailRef = useRef(null);

    useEffect(() => {
        if (emailRef) {
            emailRef.current.focus({
                cursor: 'start',
            });
        }
    }, [emailRef]);

    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [error, setError] = useState('');

    const submitForm = async (values) => {
        setIsLoading(true);
        setError('');

        try {
            await AuthService.forgottenPassword(values.email);
            setEmailSent(true);
            setIsLoading(false);
            form.resetFields();
        } catch (requestError) {
            if (
                requestError.response &&
                requestError.response.status === 404 &&
                requestError.response.data.field === 'id'
            ) {
                setEmailSent(true);
            } else {
                setError("Une erreur inconnue s'est produite. Veuillez rééssayer plus tard.");
            }

            setIsLoading(false);
        }
    };

    const validationRules = {
        email: [
            {
                required: true,
                message: "L'adresse email est obligatoire",
            },
            {
                type: 'email',
                message: 'La valeur doit être une adresse email valide',
            },
        ],
    };

    return (
        <>
            <h1>Mot de passe oublié</h1>
            <p className="form-description">
                Indiquez l&apos;adresse électronique associée à votre compte et un message de réinitialisation du mot de
                passe vous sera envoyé.
            </p>
            {emailSent && (
                <Alert
                    message="Si l'adresse électronique ci-dessous est valide, un lien pour réinitialiser le mot de passe vous sera envoyé."
                    type="success"
                    showIcon
                    className="mt-4"
                />
            )}
            {error && <Alert message={error} type="error" showIcon className="mt-4" />}
            {!emailSent && (
                <Form
                    className="mt-9 mx-auto login-form"
                    name="forgotten_password_form"
                    layout="vertical"
                    form={form}
                    initialValues={{ email: '' }}
                    requiredMark={false}
                    validateTrigger="onBlur"
                    onFinish={submitForm}
                >
                    <Form.Item label="Adresse email" name="email" rules={validationRules.email}>
                        <Input placeholder="Adresse email" ref={emailRef} />
                    </Form.Item>
                    <Form.Item className="pt-4">
                        <SubmitButton loading={isLoading}>Envoyer</SubmitButton>
                        <p className="text-center text-sm text-gray-400 italic mt-2">
                            <Link to="/login">Connexion</Link>
                        </p>
                    </Form.Item>
                </Form>
            )}
        </>
    );
};

export default ForgottenPassword;
