import moment from 'moment';
import i18n from '../../i18n';
import calendarService from '../../services/calendar.service';
import activityService from '../../services/activity.service';
import { setCalendars, setTimeUnits } from '../slices/app/calendar.slice';
import { setRequestError } from '../slices/app/error.slice';
import { RootState } from '../store';
import { buildRequestErrorObject } from '../utils/ErrorSliceUtils';
import { toCalendar, toSlice } from '../../helpers/slicing-helper';
import { toMap } from '../../helpers/array-filter';
import { incrementReRenderGantt, selectPlanningSelected, setSpinProgress } from '../slices/app/planning.slice';
import createAppAsyncThunk from './create-typed-async-thunk';
import { selectActiveTabFilterData } from '../slices/app/tab.slice';
import Activity from '../models/activity.model';

const { t } = i18n;

export const getTimeUnits = () => async (dispatch: any, getState: any) => {
    const state: RootState = getState();
    if ((window as any)._env_.REACT_APP_CALENDAR_API && state.calendar.timeUnits.length === 0) {
        calendarService
            .listTimeUnits()
            .then((data) => dispatch(setTimeUnits(data)))
            .catch((error) =>
                dispatch(setRequestError(buildRequestErrorObject(t('planning_context.error_get_time_units'), error)))
            );
    }
};

const fetchCalendars = createAppAsyncThunk(
    'calendar/fetchCalendars',
    async ({ debut = null, fin = null }: any, { dispatch, getState }) => {
        const planningSelected = selectPlanningSelected(getState());
        if (!planningSelected) return;

        const dateToString = (d: Date) => moment.utc(d).local(true).format('YYYY-MM-DD HH:mm:ss');

        const activity = await activityService.showActivity(planningSelected.rootActivityId);

        let rootActivityStartDate = debut || moment(activity.startDate).subtract(1, 'day').valueOf();
        let rootActivityEndDate = fin || moment(activity.endDate).add(1, 'day').valueOf();
        rootActivityStartDate = moment(rootActivityStartDate).subtract(1, 'day').valueOf();
        rootActivityEndDate = moment(rootActivityEndDate).add(1, 'day').valueOf();

        const calendars = await calendarService.listCalendars();

        const updatedCalendars = await Promise.all(
            calendars.map(async (calendar) => {
                try {
                    const noWorkingTime = await calendarService.listNoWorkingReal(
                        calendar.id,
                        rootActivityStartDate,
                        rootActivityEndDate
                    );

                    return {
                        ...calendar,
                        noWorkingReals: toSlice(noWorkingTime) ?? [],
                        noWorkingHours: Object.fromEntries(
                            toMap(toCalendar(toSlice(noWorkingTime), 'hours'), dateToString)
                        ),
                        noWorkingDays: Object.fromEntries(
                            toMap(toCalendar(toSlice(noWorkingTime), 'days'), dateToString)
                        ),
                        noWorkingWeek: Object.fromEntries(
                            toMap(toCalendar(toSlice(noWorkingTime), 'week'), dateToString)
                        ),
                        noWorkingMonth: Object.fromEntries(
                            toMap(toCalendar(toSlice(noWorkingTime), 'month'), dateToString)
                        ),
                    };
                } catch (error) {
                    console.error(`Error fetching calendar ${calendar.name}:`, error);
                    return calendar;
                }
            })
        );

        dispatch(setCalendars(updatedCalendars));
        dispatch(setSpinProgress(60));
    }
);

export const refetchCalendars = (activityUpdated: Activity) => async (dispatch: any, getState: any) => {
    const state: RootState = getState();
    const tabFilterData = selectActiveTabFilterData(state);
    const ganttState = (window as any).ganttInstance.getState();
    const isFiltered = tabFilterData.dateFilter;
    if (!isFiltered) {
        // test startDate and minDate acoording to current ganttState
        let newMinDate = ganttState.min_date;
        let newMaxDate = ganttState.max_date;
        let updateNoworkingDays = false;
        if (ganttState.min_date > activityUpdated.startDate) {
            newMinDate = activityUpdated.startDate;
            updateNoworkingDays = true;
        }
        if (ganttState.max_date < activityUpdated.endDate) {
            newMaxDate = activityUpdated.endDate;
            updateNoworkingDays = true;
        }
        if (updateNoworkingDays) {
            await dispatch(fetchCalendars({debut: newMinDate.valueOf(), fin: newMaxDate.valueOf()}));
            dispatch(incrementReRenderGantt());
        }
    }
};

export default fetchCalendars;
