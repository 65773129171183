import { TAB_PREFERENCES_VERSION, tabPreferencesMigratorInstance } from "../../components/utils/tab-preferences/MigratorInstance";

const migrateTabPreferences = (currentPreferences) => {
    const currentVersion = currentPreferences.version || 0;
    if (currentVersion < TAB_PREFERENCES_VERSION) {
        console.log('should migrate preferences');
        const migratedPreferences = tabPreferencesMigratorInstance.migrateObject(currentPreferences, TAB_PREFERENCES_VERSION);
       return {migratedPreferences, hasMigrated: true };
    }
    return {migratedPreferences: currentPreferences, hasMigrated: false };
}

export default migrateTabPreferences;