import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import Planning from '../../models/planning.model';
import CustomField from '../../models/custom-field.model';
import TaskStripe from '../../models/task-stripes.model';
import { RootState } from '../../store';
import { selectCurrentUser } from './user.slice';
import { canAccessToPlanning, canModifyPlanning } from '../../utils/PlanningSliceUtils';
import { ALL_JOBS } from '../../../constants/Jobs';
import { selectCalendarEntities, selectTimeUnits } from './calendar.slice';
import { selectActivityEntities, selectFilteredActivitiesCount } from './activity.slice';
/* eslint-disable no-param-reassign */

/**
 * Represents the state of the planning slice in the application.
 * @typedef {Object} PlanningSliceState
 * @property {Planning} [planningSelected] - The currently selected planning
 * @property {'' | 'live' | 'sandbox' | 'archive'} modeSelected - The current mode to fetch plannings
 * @property {CustomField[]} planningCustomFields - Custom fields specific to the planning
 * @property {CustomField[]} allCustomFields - All available custom fields
 * @property {boolean} loadingGantt - Indicates if the Gantt chart is loading
 * @property {boolean} processingGantt - Indicates if the Gantt chart is being processed
 * @property {TaskStripe[]} tasksStripesStyles - Styles for task stripes in the Gantt chart
 * @property {number} spinProgress - Progress indicator for spinning operations
 * @property {number} reRenderGantt - Trigger for re-rendering the Gantt chart
 * @property {number} refreshGantt - Trigger for refreshing the Gantt chart
 * @property {string[]} jobs - List of user jobs related to the planning
 */

export type PlanningSliceState = {
    planningSelected?: Planning;
    modeSelected: string;
    allCustomFields: CustomField[];
    loadingGantt: boolean;
    processingGantt: boolean;
    tasksStripesStyles: TaskStripe[];
    spinProgress: number;
    reRenderGantt: number;
    refreshGantt: number;
    jobs: string[];
};

const initialState: PlanningSliceState = {
    planningSelected: null,
    modeSelected: '',
    // planningCustomFields: [], // TODO change to selector?
    allCustomFields: [],
    loadingGantt: false,
    processingGantt: false,
    tasksStripesStyles: [],
    spinProgress: 0,
    reRenderGantt: 0,
    refreshGantt: 0,
    jobs: [],
};

const PlanningSlice = createSlice({
    name: 'planning',
    initialState,
    reducers: {
        setPlanningSelected: (state, action: PayloadAction<Planning>) => {
            state.planningSelected = action.payload;
        },
        setModeSelected: (state, action: PayloadAction<string>) => {
            state.modeSelected = action.payload;
        },
        setAllCustomFields: (state, action: PayloadAction<CustomField[]>) => {
            state.allCustomFields = action.payload;
        },
        setLoadingGantt: (state, action: PayloadAction<boolean>) => {
            state.loadingGantt = action.payload;
        },
        setProcessingGantt: (state, action: PayloadAction<boolean>) => {
            state.processingGantt = action.payload;
        },
        setTasksStripesStyles: (state, action: PayloadAction<TaskStripe[]>) => {
            state.tasksStripesStyles = action.payload;
        },
        setSpinProgress: (state, action: PayloadAction<number>) => {
            state.spinProgress = action.payload;
        },
        incrementReRenderGantt: (state) => {
            state.reRenderGantt += 1;
        },
        incrementRefreshGantt: (state) => {
            state.refreshGantt += 1;
        },
        setJobs: (state, action: PayloadAction<string[]>) => {
            state.jobs = action.payload;
        },
        resetPlanningData: (state) => {
            state.reRenderGantt = 0;
            state.refreshGantt = 0;
            state.spinProgress = 0;
        },
    },
});

export const {
    setPlanningSelected,
    setModeSelected,
    setAllCustomFields,
    setLoadingGantt,
    setProcessingGantt,
    setTasksStripesStyles,
    setSpinProgress,
    incrementReRenderGantt,
    incrementRefreshGantt,
    setJobs,
    resetPlanningData,
} = PlanningSlice.actions;

// Selectors

export const selectPlanningSelected = (state: RootState) => state.planning.planningSelected;
export const selectModeSelected = (state: RootState) => state.planning.modeSelected;
export const selectLoadingGantt = (state: RootState) => state.planning.loadingGantt;
export const selectProcessingGantt = (state: RootState) => state.planning.processingGantt;
export const selectTasksStripesStyles = (state: RootState) => state.planning.tasksStripesStyles;
export const selectSpinProgress = (state: RootState) => state.planning.spinProgress;
export const selectReRenderGantt = (state: RootState) => state.planning.reRenderGantt;
export const selectRefreshGantt = (state: RootState) => state.planning.refreshGantt;
export const selectJobs = (state: RootState) => state.planning.jobs;
// Cached selectors

export const selectAllCustomFields = (state: RootState) => state.planning.allCustomFields;

export const selectPlanningCustomFields = createSelector(selectAllCustomFields, (allCustomFields) =>
    allCustomFields.filter((cf) => cf.id > 0)
);

export const canAccessToCurrentPlanning = (state: RootState) => {
    const currentUser = selectCurrentUser(state);
    if (currentUser.readOnly) {
        return false;
    }
    return canAccessToPlanning(selectPlanningSelected(state), currentUser);
};

export const canModify = (state: RootState) => {
    const currentUser = selectCurrentUser(state);
    if (currentUser.readOnly) {
        return false;
    }
    return canModifyPlanning(selectPlanningSelected(state), currentUser);
};


export const selectUserJobsWithoutMulti = createSelector([selectJobs], (jobs) =>
    jobs.filter((job) => job !== ALL_JOBS)
);

export const selectIsUserJob = createSelector(
    [selectJobs],
    (jobs) => (job: string[]) => jobs.some((userJob) => job.includes(userJob)) || job.includes(ALL_JOBS)
);

export const selectIsMultiJobUser = createSelector([selectJobs], (jobs) => jobs.includes(ALL_JOBS));

/**
 * Selects and combines various pieces of state to create a comprehensive data object for the Gantt chart.
 * @param {RootState} state - The root state of the Redux store.
 * @returns {Object}
 * Used to IMPROVE PERFORMANCE and not using too much useSelector in component
 */
export const selectGanttData = createSelector(
    [
        selectPlanningSelected,
        selectPlanningCustomFields,
        selectModeSelected,
        selectCalendarEntities,
        selectTasksStripesStyles,
        selectTimeUnits,
        selectReRenderGantt,
        selectIsUserJob,
        selectIsMultiJobUser,
        canModify,
        selectAllCustomFields,
        selectActivityEntities,
        selectLoadingGantt,
        selectFilteredActivitiesCount,
        selectJobs, 
        selectRefreshGantt,
    ],
    (
        planningSelected,
        planningCustomFields,
        modeSelected,
        calendarsDictionary,
        tasksStripesStyles,
        timeUnits,
        reRenderGantt,
        isUserJob,
        isMultiJobUser,
        canAccess,
        allCustomFields,
        activitiesDictionary,
        loadingGantt,
        filteredActivitiesCount,
        jobs, 
        refreshGantt
    ) => ({
        planningSelected,
        planningCustomFields,
        modeSelected,
        calendarsDictionary,
        tasksStripesStyles,
        timeUnits,
        reRenderGantt,
        isUserJob,
        isMultiJobUser,
        canAccess,
        allCustomFields,
        activitiesDictionary,
        loadingGantt,
        filteredActivitiesCount,
        jobs,
        refreshGantt
    })
);

export default PlanningSlice.reducer;
