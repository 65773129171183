import { getPlanningAccess, USER_ACCESS } from '../../helpers/planning';
import folderService from '../../services/folder.service';

export const canAccessToPlanning = (planning, currentUser) =>
    [USER_ACCESS.ADMIN, USER_ACCESS.OWNER, USER_ACCESS.READWRITE, USER_ACCESS.READ].includes(
        getPlanningAccess(planning, currentUser)
    );
    
export const canModifyPlanning = (planning, currentUser) =>
    [USER_ACCESS.ADMIN, USER_ACCESS.OWNER, USER_ACCESS.READWRITE].includes(
        getPlanningAccess(planning, currentUser)
    );



export const requestPlanningsInFolderForMode = (folderId, mode) => {
    switch (mode) {
        case 'archive':
            return folderService.listArchives(folderId);
        case 'sandbox':
            return folderService.listSandboxPlannings(folderId);
        case 'live':
            return folderService.listPlannings(folderId);
        default:
            return folderService.listPlannings(folderId);
    }
};
export default canAccessToPlanning;
