import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { orderBy, sortBy } from 'lodash';
import Tab, { TabFilterData } from '../../models/tab.model';
import TabPreferences from '../../models/tab-preferences.model';
import authService from '../../../services/auth.service';
import { conditionEnumNoTranslation, customFieldsIds, defaultQuery } from '../../../components/filters/FilterUtils';
import { TAB_TYPE } from '../../../constants/Tabs';
import { RootState } from '../../store';
import migrateTabPreferences from '../../utils/TabSliceUtils';
import createAppAsyncThunk from '../../thunks/create-typed-async-thunk';
import { notificationSuccess } from '../../../helpers/notification';
import { selectPlanningSelected } from './planning.slice';
import i18n from '../../../i18n';
import { updateUserPreferences } from './user.slice';

/**
 * @typedef {Object} TabSliceState
 * @property {Tab[]} planningTabs - An array of Tab objects representing the planning tabs.
 * @property {Tab} activeTab - A single Tab object representing the currently active tab.
 * @property {TabPreferences} tabPreferences - A TabPreferences object representing the user's preferences for active tab.
 * @property {Tab[]} sharedTabs - An array of Tab objects representing the shared tabs.
 * @property {Object} activeTabFilterData - An object containing active filters on active tab and data of all filters in the tab.
 * @property {string[]} activeTabFilterData.activeFilters - An array of strings representing id of active filters on active tab .
 * @property {Filter[]} activeTabFilterData.rawData - An array of Filter objects representing the raw data of all filters for the active tab.
 */

export type TabSliceState = {
    planningTabs: Tab[];
    activeTab: Tab;
    tabPreferences: TabPreferences;
    sharedTabs: Tab[];
    activeTabFilterData: TabFilterData;
};
const initialState: TabSliceState = {
    planningTabs: [],
    tabPreferences: null,
    sharedTabs: [],
    activeTabFilterData: {
        activeFilters: [],
        rawData: [],
    },
    activeTab: null, // TODO: should be selector?
};

// Create async thunks
export const fetchPlanningTabs = createAppAsyncThunk('tabs/fetchPlanningTabs', async (planningId: number) => {
    const tabs = await authService.getPlanningTabs(planningId);
    if (tabs.length === 0) {
        throw new Error('No planning tabs found');
    }
    return sortBy(tabs, (i) => i.order);
});

export const createPlannningTab = createAppAsyncThunk('tabs/createPlanningTab', async (planningId: number) => {
    const newTab = await authService.createPlanningTab(planningId, TAB_TYPE.GANTT, 'Gantt');
    return [newTab];
});

export const fetchSharedPlanningTabs = createAppAsyncThunk(
    'tabs/fetchSharedPlanningTabs',
    async (planningId: number) => {
        const sharedTabs = await authService.getSharedPlanningTabs(planningId);
        return orderBy(sharedTabs, ['id'], ['desc']);
    }
);


export const updateActiveTabFilterData = createAppAsyncThunk(
    'tabs/updateActiveTabFilterData',
    async ({ tabId, filterData }: { tabId: number; filterData: any }) =>
        authService.updatePlanningTabFilterData(tabId, filterData)
);
export const getActiveTabData = createAppAsyncThunk(
    'tabs/getActiveTabData',
    async (planningId: number, thunkApi: any) => {
        const result: { preferences: TabPreferences; filterData: TabFilterData } = {
            preferences: null,
            filterData: null,
        };
        
        const state: RootState = thunkApi.getState();
        await thunkApi.dispatch(updateUserPreferences({activeTab: {...(state.user.userPreferences.activeTab || {}), [state.planning.planningSelected.id]: state.tab.activeTab.id}}))
        const tabData = await authService.getPlanningTab(state.tab.activeTab.id);
        // MIGRATE IF NEEDED
        const preferencesMigrationResult = migrateTabPreferences(tabData?.preferences);
        const preferencesToSet = preferencesMigrationResult.hasMigrated
            ? preferencesMigrationResult.migratedPreferences
            : tabData?.preferences;
        if (preferencesMigrationResult.hasMigrated) {
            await await authService.updatePlanningTabWithPreferences(
                state.tab.activeTab.id,
                preferencesMigrationResult.migratedPreferences
            );
        }
        result.preferences = preferencesToSet;
        // console.log("🚀 ~ file: TabContext.jsx:115 ~ onActiveTabChange ~ preferencesToSet:", preferencesToSet)
        // console.log(
        //     '🚀 ~ file: TabContext.jsx:123 ~ onActiveTabChange ~ tabData?.filters:',
        //     tabData?.filters
        // );
        const preferenceFilterData = state.user.userPreferences?.filters?.[planningId] || [];
        // verify raw data
        const newFilterData: TabFilterData = {
            activeFilters: [],
            rawData: [],
            dateFilter: tabData?.filters?.dateFilter || undefined,
        };
        (tabData?.filters?.activeFilters || []).forEach((id) => {
            const filter = preferenceFilterData.find((i) => i.id === id);
            if (filter) {
                newFilterData.activeFilters.push(filter.id);
                newFilterData.rawData.push(filter);
            }
        });
        // console.log('🚀 ~ file: TabContext.jsx:135 ~ onActiveTabChange ~ newFilterData:', newFilterData);
        result.filterData = newFilterData;
        return result;
    }
);

// Create the slice
const tabSlice = createSlice({
    name: 'tabs',
    initialState,
    reducers: {
        setTabPreferences: (state, action) => {
            state.tabPreferences = action.payload;
        },
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
        setPlanningTabs: (state, action) => {
            state.planningTabs = action.payload;
        },
        setActiveTabFilterData: (state, action) => {
            state.activeTabFilterData = action.payload;
        },
        refreshPlanningTabsByAction: (state, action: PayloadAction<{ action: string; object: any }>) => {
            const { action: actionType, object } = action.payload;
            console.log("🚀 ~ object:", object)
            switch (actionType) {
                case 'new':
                    state.planningTabs.push(object);
                    break;
                case 'edit': {
                    const indexTab = state.planningTabs.findIndex((i) => i.id === object.id);
                    if (indexTab > -1) {
                        state.planningTabs[indexTab] = object;
                    }
                    break;
                }
                case 'delete':
                    state.planningTabs = state.planningTabs.filter((tab) => tab.id !== object.id);
                    break;
                default:
                    break;
            }
            state.planningTabs = sortBy(state.planningTabs, (i) => i.order);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPlanningTabs.fulfilled, (state, action) => {
                state.planningTabs = action.payload;
            })
            .addCase(createPlannningTab.fulfilled, (state, action) => {
                state.planningTabs = action.payload;
            })
            .addCase(getActiveTabData.fulfilled, (state, action) => {
                state.tabPreferences = action.payload.preferences;
                state.activeTabFilterData = action.payload.filterData;
            })
            .addCase(getActiveTabData.rejected, (state) => {
                state.tabPreferences = {};
            })
            .addCase(fetchSharedPlanningTabs.fulfilled, (state, action) => {
                state.sharedTabs = action.payload;
            })
            .addCase(updateActiveTabFilterData.fulfilled, (state, action) => {
                state.activeTabFilterData = action.payload.filters;
            });
    },
});

// Export actions
export const { setTabPreferences, setActiveTab, setPlanningTabs, setActiveTabFilterData, refreshPlanningTabsByAction } =
    tabSlice.actions;

// Export reducer
export default tabSlice.reducer;

// Thunks

export const refreshPlanningTabs = (action: string, object: Tab) => async (dispatch: any) => dispatch(refreshPlanningTabsByAction({ action, object }));
export const chooseActiveTab = (tabs: Tab[], planningId) => async (dispatch: any, getState: any) => {
    const state: RootState = getState();
    const activeTabs = tabs.filter((tab) => tab.display);
    /**
     * Sets the active tab based on user preference or defaults to the first active tab.
     * If a preferred active tab is found, it's set as the active tab.
     * Otherwise, the first active tab (or null if no active tabs) is set as the active tab.
     */
    const preferredActiveTab = activeTabs.find((tab) => tab.id === state.user.userPreferences.activeTab?.[planningId]);
    if (preferredActiveTab) {
        await dispatch(setActiveTab(preferredActiveTab));
    } else {
        await dispatch(setActiveTab(activeTabs[0] || null));
    }
};

export const updateTabPreferences = (newPreferences, updateServer = true) => async (dispatch: any, getState: any)  => {
    const { activeTab } = getState().tab;
    const { tabPreferences } = getState().tab;
    const updatedPreference = { ...tabPreferences, ...newPreferences };
    dispatch(setTabPreferences(updatedPreference));

    if (updateServer) {
        try {
            return authService.updatePlanningTabWithPreferences(activeTab.id, updatedPreference);
        } catch (error) {
            throw new Error('Error updating tab preferences');
        }
    }
    return updatedPreference;
}

export const enableDateFilter =
    (start: number, end: number | null = null) =>
    async (dispatch: any, getState: any) => {
        const dateFilter = { ...defaultQuery };
        dateFilter.rules.push({
            id: crypto.randomUUID(),
            field: customFieldsIds.startDate,
            operator: conditionEnumNoTranslation.LOWER_OR_EQUAL.name,
            value: end,
        });
        dateFilter.rules.push({
            id: crypto.randomUUID(),
            field: customFieldsIds.endDate,
            operator: conditionEnumNoTranslation.UPPER_OR_EQUAL.name,
            value: start,
        });

        const state: RootState = getState();
        const { activeTab } = state.tab;
        const currentFilterData = state.tab.activeTabFilterData;
        await dispatch(updateActiveTabFilterData({ tabId: activeTab.id, filterData: { ...currentFilterData, dateFilter } }));
    };

export const disableDateFilter = () => async (dispatch: any, getState: any) => {
    const state: RootState = getState();
    const { activeTab } = state.tab;
    const { activeTabFilterData } = state.tab;

    if (activeTabFilterData.dateFilter) {
        const newFilterData = { ...activeTabFilterData };
        delete newFilterData.dateFilter;
        await dispatch(updateActiveTabFilterData({ tabId: activeTab.id, filterData: newFilterData }));
    }
};

export const getPlanningTabs = () => async (dispatch: any, getState: any) => {
    const planningSelected = selectPlanningSelected(getState());
    return Promise.all([
        dispatch(fetchSharedPlanningTabs(planningSelected.id)),
        dispatch(fetchPlanningTabs(planningSelected.id))
            .then(async ({ payload }: any) => {
                await dispatch(chooseActiveTab(payload, planningSelected.id));
                await dispatch(getActiveTabData(planningSelected.id));
            })
            .catch(async () => {
                const { payload }: any = await dispatch(createPlannningTab(planningSelected.id));
                notificationSuccess(i18n.t('tab_context.tab_added_auto'));
                await dispatch(chooseActiveTab(payload, planningSelected.id));
                await dispatch(getActiveTabData(planningSelected.id));
            }),
    ]);
};

// Selectors

export const selectPlanningTabs = (state: RootState) => state.tab.planningTabs;
export const selectActiveTab = (state: RootState) => state.tab.activeTab;
export const selectTabPreferences = (state: RootState) => state.tab.tabPreferences;
export const selectActiveTabFilterData = (state: RootState) => state.tab.activeTabFilterData;
export const selectSharedTabs = (state: RootState) => state.tab.sharedTabs;
