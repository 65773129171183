import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import TimeSliceDictionary from '../../models/time-slice.model';
import { RootState } from '../../store';

export interface ActivityCalendarState {
    scale: string | null;
    activityCalendarSliceDictionary: TimeSliceDictionary;
}


const initialState: ActivityCalendarState = {
    scale: null,
    activityCalendarSliceDictionary: {},
};





const activityCalendarSlice = createSlice({
    name: 'activityCalendar',
    initialState,
    reducers: {
        setScale: (state, action: PayloadAction<string | null>) => {
            state.scale = action.payload;
        },
        setActivityCalendarSliceDictionary: (state, action: PayloadAction<TimeSliceDictionary>) => {
            state.activityCalendarSliceDictionary = action.payload;
        },
        updateActivityCalendarSlice: (state, action: PayloadAction<{ taskId: number; slices: any[] }>) => {
            const { taskId, slices } = action.payload;
            state.activityCalendarSliceDictionary[taskId] = slices;
        },
        removeActivityCalendarSlice: (state, action: PayloadAction<number>) => {
            delete state.activityCalendarSliceDictionary[action.payload];
        },
        clearActivityCalendarSliceDictionary: (state) => {
            state.activityCalendarSliceDictionary = {};
        },
    },
});

export const { 
    setScale, 
    setActivityCalendarSliceDictionary, 
    updateActivityCalendarSlice, 
    removeActivityCalendarSlice, 
    clearActivityCalendarSliceDictionary 
} = activityCalendarSlice.actions;

export const selectScale = (state: RootState) => state.activityCalendarSlice.scale;
export const selectActivityCalendarSlice = (state: RootState) => state.activityCalendarSlice.activityCalendarSliceDictionary;
export const selectActivityCalendarSliceById = (state: RootState, id: number) => 
    state.activityCalendarSlice.activityCalendarSliceDictionary[id];
export default activityCalendarSlice.reducer;
