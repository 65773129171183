import { formatResourceTask } from '../../helpers/planning';

const formatResourceTaskByType = (
    { id, newActivityCalendarSlices, startDate, endDate, calendarId, type },
    allocatedNeed,
    needType
) => ({
    ...formatResourceTask(id, newActivityCalendarSlices, allocatedNeed.needLabel, startDate, endDate, false),
    quantity: allocatedNeed.quantity,
    calendarId,
    needType,
    parentType: type,
    class: `resource-task-${allocatedNeed?.needId || 'empty'}`,
});

export const CalendarNoWorkingAttribute = {
    hours: 'noWorkingHours',
    days: 'noWorkingDays',
    week: 'noWorkingWeek',
    month: 'noWorkingMonth',
};

export const shouldUpdateSlices = (fields) =>
    fields.filter((item) => {
        const existence = ['allocatedNeed', 'startDate', 'endDate', 'calendarId'].indexOf(item) !== -1;
        return existence;
    }).length > 0;

export default formatResourceTaskByType;
