import { queryBuilderToUserFilter } from '../../components/filters/FilterUtils';
import { flattenGanttNodesOpt, generateFilteredGanttNodes, generateGroupedGanttNodes } from '../../helpers/planning';
import i18n from '../../i18n';
import activityService from '../../services/activity.service';
import {
    selectActivityById,
    selectActivityEntities,
    selectBaselineActivityById,
    setActivities,
    setBaselineActivities,
    updateActivity,
} from '../slices/app/activity.slice';
import { selectTimeUnitById, selectTimeUnits } from '../slices/app/calendar.slice';
import { setNotificationError, setRequestError } from '../slices/app/error.slice';
import {
    selectModeSelected,
    selectPlanningCustomFields,
    selectPlanningSelected,
} from '../slices/app/planning.slice';
import { selectActiveTabFilterData, selectTabPreferences } from '../slices/app/tab.slice';
import calculateTaskDuration from '../utils/ActivitySliceUtils';
import buildNotificationErrorObject, { buildRequestErrorObject } from '../utils/ErrorSliceUtils';
import createAppAsyncThunk from './create-typed-async-thunk';

const { t } = i18n;

export const generateFilteredFlattenActivities = createAppAsyncThunk(
    'activity/generateFilteredFlattenActivities',
    async (_, { getState, dispatch }) => {
        const state = getState();
        const tabPreferences = selectTabPreferences(state);
        const tabFilterData = selectActiveTabFilterData(state);
        console.log("🚀 ~ tabFilterData:", tabFilterData)
        const planningSelected = selectPlanningSelected(state);
        const modeSelected = selectModeSelected(state);
        const activitiesDictionary = selectActivityEntities(state);
        const timeUnits = selectTimeUnits(state);
        const customFields = selectPlanningCustomFields(state);

        const filteredGroup = tabPreferences?.filtered_group;
        const filterDataToSend = tabFilterData?.rawData.map((i) =>
            queryBuilderToUserFilter(i.data, timeUnits, customFields, modeSelected === 'archive')
        );

        if (tabFilterData?.dateFilter) {
            filterDataToSend.push(queryBuilderToUserFilter(tabFilterData.dateFilter, timeUnits));
        }

        const isColorFieldExist = customFields.some((field) => field.id === tabPreferences?.planning_color?.id);
        const colorField = isColorFieldExist ? tabPreferences?.planning_color : null;

        try {
            const activities =
                filteredGroup?.groupingType === 'custom_fields'
                    ? await generateGroupedGanttNodes(
                          activitiesDictionary,
                          planningSelected.summaryId ?? planningSelected.rootActivityId,
                          colorField,
                          filteredGroup.simpleActivityOnly,
                          filteredGroup.sortOptions,
                          filterDataToSend
                      )
                    : await generateFilteredGanttNodes(
                          activitiesDictionary,
                          planningSelected.summaryId ?? planningSelected.rootActivityId,
                          colorField,
                          filterDataToSend
                      );

            return flattenGanttNodesOpt(activities);
        } catch (error) {
            dispatch(
                setNotificationError(
                    buildNotificationErrorObject(
                        'Gantt',
                        t(
                            filteredGroup?.groupingType === 'custom_fields'
                                ? 'gantt.error_grouping'
                                : 'gantt.error_filtering'
                        ),
                        { duration: 0 }
                    )
                )
            );
            return [];
        }
    }
);

export const fetchActivities = createAppAsyncThunk(
    'planning/fetchActivities',
    async (_, { getState, dispatch, rejectWithValue }) => {
        const state = getState();
        const tabPreferences = selectTabPreferences(state);
        const planningSelected = selectPlanningSelected(state);
        const modeSelected = selectModeSelected(state);
        const customFields = selectPlanningCustomFields(state);
        const existingDictionary = selectActivityById(state, planningSelected.rootActivityId);

        if (!planningSelected) return rejectWithValue('No planning selected');

        if (existingDictionary) {
            return dispatch(generateFilteredFlattenActivities()).unwrap();
        }

        const baseLinePreference = tabPreferences?.gantt_baseline;

        if (baseLinePreference?.showBaseLine && baseLinePreference?.baseLinePlanning) {
            const activities = await activityService.generateFlatten(
                baseLinePreference.baseLinePlanning.rootActivityId
            );
            await dispatch(setBaselineActivities(activities));
        }
        try {
            const activitiesList = await activityService.generateFlatten(planningSelected.rootActivityId);
            const dictionary = activitiesList.map((act) => {
                const baseLineActivity = selectBaselineActivityById(getState(), act.identity);
                const activity = act;

                activity.baseLineStartDate = baseLineActivity?.startDate || null;
                activity.baseLineEndDate = baseLineActivity?.endDate || null;
                activity.baseLineProgress = baseLineActivity?.avancement || 0;
                const durationUnit = selectTimeUnitById(state, activity.dayDefinitionId);

                activity.taskDuration = calculateTaskDuration(activity, durationUnit);

                if (modeSelected === 'archive') {
                    activity.unifiedfields = act.unifiedfields.map((unifiedField) => ({
                        ...unifiedField,
                        id: customFields.find((i) => i.realId === unifiedField.id)?.id,
                    }));
                }
                return activity;
            });

            return dispatch(setActivities(dictionary));
            // return dispatch(generateFilteredFlattenActivities()).unwrap();
        } catch (error) {
            return dispatch(
                setRequestError(buildRequestErrorObject(t('planning_context.error_get_activities'), error))
            );
        }
    }
);

export const updateActivitiesDictionary = (activityId: number, updatedInfo) => async (dispatch: any, getState: any) => {
    const state = getState();
    const currentActivityData = selectActivityById(state, activityId);
    const dayDefinitionId = Number(currentActivityData?.dayDefinitionId ?? updatedInfo.dayDefinitionId);
    const durationUnit = selectTimeUnitById(state, dayDefinitionId);
    const taskDuration = calculateTaskDuration(updatedInfo ?? currentActivityData, durationUnit);
    const activityData = {
        ...updatedInfo,
        id: activityId,
        taskDuration,
    };

    dispatch(updateActivity(activityData));
};

export default fetchActivities;
