import { ALL_GLOBAL_NEEDS, ALL_PLANNING_NEEDS } from "../../constants/Needs";
import i18n from "../../i18n";
import Need from "../models/need.model";

const formatNeeds = (list: Need[], parent: number | null, isPlanning: boolean): Need[] =>
    list.map((el) => ({
        ...el,
        key: el.id.toString(),
        parent,
        open: true,
        name:
            el.name === (isPlanning ? ALL_PLANNING_NEEDS : ALL_GLOBAL_NEEDS)
                ? i18n.t(isPlanning ? 'needs.all_planning_needs' : 'needs.all_global_needs')
                : el.name,
        children: el.isLevel ? formatNeeds(el.children || [], el.id, isPlanning) : undefined,
    }));

export default formatNeeds;
