import { computeActivityCalendarSlice } from '../../helpers/slicing-helper';
import GanttConfigurations from '../../components/plannings/gantt_config';
import { xAxisDateFormat } from '../../components/charts/CustomHistogramDateXaxis';
import { RESOURCE_VIEW_MODE } from '../../constants/Generic';
import { TAB_TYPE } from '../../constants/Tabs';
import i18n, { translateWithPrefix } from '../../i18n';
import { NEED_TYPE } from '../../constants/Needs';
import TimeSliceDictionary from '../models/time-slice.model';
import createAppAsyncThunk from './create-typed-async-thunk';
import { selectGanttData } from '../slices/app/planning.slice';
import { selectActiveTab, selectTabPreferences } from '../slices/app/tab.slice';
import { selectCalendarEntities } from '../slices/app/calendar.slice';
import formatResourceTaskByType, { CalendarNoWorkingAttribute } from '../utils/ActivityCalendarSliceUtils';
/* eslint-disable */
import {
    selectScale,
    setActivityCalendarSliceDictionary,
    setScale,
    updateActivityCalendarSlice,
} from '../slices/slicing/activity-calendar.slice';
import  computeChartData, {
    
    computeActivityCalendarTemporalSliceDictionary,
    updateChartOptions,
    updateNeedList,
} from './need-chart-slice.thunk';
import { restoreScrollState } from '../../components/plannings/gantt_events';

export const updateActivityResourceVirtualTasks = createAppAsyncThunk(
    'activityCalendar/updateResourceVirtualTasks',
    async ({ taskId, updatedData, isBroadcast = true, slices = [] }: any, { getState, dispatch }) => {
        const state = getState();
        const generalTranslation = translateWithPrefix('general');
        const planningPreferences = selectTabPreferences(state);
        const calendarsDictionary = selectCalendarEntities(state);
        const scale = selectScale(state);

        if (
            !(window as any).ganttInstance &&
            planningPreferences?.gantt_parameters?.showResources !== RESOURCE_VIEW_MODE(i18n).NEEDS.value
        ) {
           return 'No need to update resource virtual tasks. Resource view mode is not set to "Needs".';
        }
        (window as any).saveScrollState = false;

        const tasksToUpdate = (window as any).ganttInstance.getTaskBy('serverId', updatedData.id, {
            project: true,
            task: true,
            milestone: true,
        });
        if (isBroadcast) {
            tasksToUpdate.forEach(({ id }) => {
                (window as any).ganttInstance.getChildren(id).forEach((childId) => {
                    (window as any).ganttInstance.silent(() => {
                        const child = (window as any).ganttInstance.getTask(childId);
                        if (child.isResource) {
                            (window as any).ganttInstance.deleteTask(childId);
                        }
                    });
                });
            });
        }

        let newActivityCalendarSlices;

        if (isBroadcast) {
            newActivityCalendarSlices = computeActivityCalendarSlice(
                calendarsDictionary[updatedData.calendarId]?.[
                    CalendarNoWorkingAttribute[xAxisDateFormat(generalTranslation)[scale].mainScaleUnit]
                ] ?? [],
                xAxisDateFormat(generalTranslation)[scale].mainScaleUnit,
                updatedData.startDate,
                updatedData.endDate
            );
            dispatch(updateActivityCalendarSlice({ taskId, slices: newActivityCalendarSlices }));
        } else {
            newActivityCalendarSlices = slices;
        }

        const activityAllocatedNeeds = updatedData.allocatedNeed || [];

        [...activityAllocatedNeeds.nonConsumable.global, ...activityAllocatedNeeds.nonConsumable.planning].forEach(
            (allocatedNeed, index) => {
                tasksToUpdate.forEach(({ id, startDate, endDate, calendarId, type }) => {
                    (window as any).ganttInstance.silent(() => {
                        (window as any).ganttInstance.addTask(
                            formatResourceTaskByType(
                                { id, newActivityCalendarSlices, startDate, endDate, calendarId, type },
                                allocatedNeed,
                                NEED_TYPE.NON_CONSUMMABLE
                            ),
                            id,
                            index
                        );
                    });
                });
            }
        );
        [...activityAllocatedNeeds.consumable.global, ...activityAllocatedNeeds.consumable.planning].forEach(
            (allocatedNeed, index) => {
                tasksToUpdate.forEach(({ id, startDate, endDate, calendarId, type }) => {
                    (window as any).ganttInstance.silent(() => {
                        (window as any).ganttInstance.addTask(
                            formatResourceTaskByType(
                                { id, newActivityCalendarSlices, startDate, endDate, calendarId, type },
                                allocatedNeed,
                                NEED_TYPE.CONSUMMABLE
                            ),
                            id,
                            index
                        );
                    });
                });
            }
        );
        if (isBroadcast) {
            (window as any).ganttInstance.render();
            (window as any).ganttInstance.refreshData();
        }

        (window as any).saveScrollState = true;

        return { taskId, newActivityCalendarSlices };
    }
);

const computeActivityCalendarSliceDictionary = createAppAsyncThunk(
    'activityCalendar/computeSliceDictionary',
    async (_, { getState, dispatch }) => {
        const state = getState();
        const generalTranslation = translateWithPrefix('general');
        const planningPreferences = selectTabPreferences(state);
        const activeTab = selectActiveTab(state);
        const scaleConfigs = GanttConfigurations.scaleConfigs(generalTranslation);
        const scale = planningPreferences?.gantt_scales || scaleConfigs[1].name;
        await dispatch(setScale(scale));
        const showHistogram =
            activeTab?.tabType === TAB_TYPE.GANTT
                ? planningPreferences?.gantt_parameters?.showResources === RESOURCE_VIEW_MODE(i18n).NEEDS.value
                : activeTab?.tabType === TAB_TYPE.RESOURCE;
        const { activitiesDictionary, calendarsDictionary } = selectGanttData(state);

        if (!showHistogram) {
            return null;
        }

        const dictionary: TimeSliceDictionary = {};
        const scaleUnit: string = xAxisDateFormat(generalTranslation)[scale].mainScaleUnit;
        /* eslint-disable */
        for (const activity of Object.values(activitiesDictionary)) {
            const hasAllocatedNeeds =
                activity.allocatedNeed.nonConsumable.global.length > 0 ||
                activity.allocatedNeed.nonConsumable.planning.length > 0 ||
                activity.allocatedNeed.consumable.global.length > 0 ||
                activity.allocatedNeed.consumable.planning.length > 0;

            if (hasAllocatedNeeds) {
                const noWorkingPeriods =
                    calendarsDictionary[activity.calendarId]?.[CalendarNoWorkingAttribute[scaleUnit]] ?? [];

                dictionary[activity.id] = computeActivityCalendarSlice(
                    noWorkingPeriods,
                    scaleUnit,
                    activity.startDate,
                    activity.endDate
                );
                if ( activeTab?.tabType === TAB_TYPE.GANTT) {
                    dispatch(
                        updateActivityResourceVirtualTasks({
                            taskId: activity.id,
                            updatedData: activity,
                            isBroadcast: false,
                            slices: dictionary[activity.id],
                        })
                    );
                }
            }
        }
        if ((window as any).ganttInstance) {
            // setTimeout(() => {
            (window as any).ganttInstance.render();
            // }, 1000);
        }
        await dispatch(setActivityCalendarSliceDictionary(dictionary));
        await dispatch(updateChartOptions({}));
        await dispatch(updateNeedList());
        // await dispatch(computeActivityCalendarTemporalSliceDictionary());
        // await dispatch(computeChartData());
        restoreScrollState();
        return dictionary;
    }
);

export default computeActivityCalendarSliceDictionary;
